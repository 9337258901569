<template>
  <div class="change-password-container">
    <!--    <v-card class="desktop_card d-none">-->
    <!--      <v-card-title>-->
    <!--        <label class="&#45;&#45;v-primary-base text-left text-capitalize">{{ $t(`label.changePassword`) }}</label>-->
    <!--      </v-card-title>-->
    <!--      <v-card class="profile-detail-card" elevation="0">-->
    <!--        <v-form ref="changePasswordForm">-->
    <!--          <v-row no-gutters align="end" class="px-8 pt-4">-->
    <!--            <v-col lg="4" md="5">-->
    <!--              <app-form-field-->
    <!--                v-model.trim="changePasswordObj.oldPassword"-->
    <!--                :enabledLabel="true"-->
    <!--                :label="$t(`field.currentPassword`)"-->
    <!--                :placeholder="$t(`fieldHint.fillUpPwdHere`)"-->
    <!--                :rules="validator.passwordRules()"-->
    <!--                type="password"-->
    <!--              ></app-form-field>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <v-row no-gutters align="end" class="px-8">-->
    <!--            <v-col lg="4" md="5">-->
    <!--              <app-form-field-->
    <!--                v-model.trim="changePasswordObj.newPassword"-->
    <!--                :enabledLabel="true"-->
    <!--                :tooltip="$t(`fieldHint.password`)"-->
    <!--                :label="$t(`field.newPassword`)"-->
    <!--                :placeholder="$t(`fieldHint.fillUpPwdHere`)"-->
    <!--                :rules="validator.passwordRules(memberInfo.memberCode)"-->
    <!--                type="password"-->
    <!--              ></app-form-field>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <v-row no-gutters align="end" class="px-8">-->
    <!--            <v-col lg="4" md="5" class="mb-4">-->
    <!--              <app-form-field-->
    <!--                v-model.trim="changePasswordObj.confirmNewPassword"-->
    <!--                :enabledLabel="true"-->
    <!--                :label="$t(`field.confirmNewPassword`)"-->
    <!--                :placeholder="$t(`fieldHint.fillUpPwdHere`)"-->
    <!--                :rules="validator.confirmPasswordRules(changePasswordObj.newPassword, changePasswordObj.confirmNewPassword)"-->
    <!--                type="password"-->
    <!--              ></app-form-field>-->
    <!--              <app-button class="dialog-button" :action="this.changePassword" :title="$t(`button.submit`)"></app-button>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-form>-->
    <!--      </v-card>-->
    <!--    </v-card>-->
    <v-form class="mobile" ref="changePasswordForm">
      <div class="change-password-wrapper">
        <div class="px-2 change-password-row">
          <v-col class="no-gutters" md="3">
            <label class="white__text">{{ $t(`field.currentPassword`) }}</label>
          </v-col>
          <v-text-field
            v-model.trim="changePasswordObj.oldPassword"
            class="change-password-input"
            :type="passwordType ? 'password' : 'text'"
            clearable
            append-icon="remove_red_eye"
            @click:append="passwordType = !passwordType"
            :rules="validator.passwordRules()"
            :placeholder="$t(`fieldHint.fillUpPwdHere`)"
          ></v-text-field>
        </div>
        <div class="profile-divider"></div>
        <div class="px-2 change-password-row">
          <v-col class="no-gutters" md="3">
            <label class="white__text">{{ $t(`field.password`) }}</label>
          </v-col>
          <v-text-field
            v-model.trim="changePasswordObj.newPassword"
            class="change-password-input"
            :type="newPassword ? 'password' : 'text'"
            clearable
            append-icon="remove_red_eye"
            @click:append="newPassword = !newPassword"
            :rules="validator.passwordRules(memberInfo.memberCode)"
            :placeholder="$t(`fieldHint.fillUpPwdHere`)"
          ></v-text-field>
        </div>
        <div class="profile-divider"></div>
        <div class="px-2 change-password-row">
          <v-col class="no-gutters" md="3">
            <label class="white__text">{{ $t(`field.confirmNewPassword`) }}</label>
          </v-col>
          <v-text-field
            v-model.trim="changePasswordObj.confirmNewPassword"
            class="change-password-input"
            :type="confirmNewPassword ? 'password' : 'text'"
            clearable
            append-icon="remove_red_eye"
            @click:append="confirmNewPassword = !confirmNewPassword"
            :rules="validator.confirmPasswordRules(changePasswordObj.newPassword, changePasswordObj.confirmNewPassword)"
            :placeholder="$t(`fieldHint.fillUpPwdHere`)"
          ></v-text-field>
        </div>
        <div class="full-width d-flex justify-center">
          <v-btn class="change-password-button" @click="changePassword">{{ $t(`button.submit`) }}</v-btn>
          <!-- <app-button customClass="" :action="this.changePassword" :title="$t(`button.submit`)"></app-button> -->
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import stingFormat from 'string-format'
import { locale, formValidator, errorCodeHelper } from '@/util'
import { MEMBER_CHANGE_PASSWORD, MEMBER_RESET_CHANGE_PASSWORD_STORE } from '@/store/member.module'
export default {
  name: 'appChangePassword',
  mounted() {
    this.$refs.changePasswordForm.reset()
  },
  data: () => ({
    validator: formValidator,
    stingFormat: stingFormat,
    retainSignIn: false,
    changePasswordObj: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    passwordType: true,
    newPassword: true,
    confirmNewPassword: true
  }),
  computed: {
    memberInfo() {
      return this.$store.state.member.info
    },
    changePasswordResponseComplete() {
      return this.$store.state.member.changePasswordResponse.complete
    }
  },
  watch: {
    changePasswordResponseComplete() {
      let response = this.$store.state.member.changePasswordResponse
      if (response.complete) {
        this.changePasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CHANGE_PASSWORD_STORE}`)
      }
    }
  },
  methods: {
    changePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        let changePasswordObj = this.changePasswordObj
        this.$store.dispatch(`${MEMBER_CHANGE_PASSWORD}`, {
          changePasswordObj
        })
      }
    },
    changePasswordResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberPasswordUpdated`), true)
      }
    }
  }
}
</script>

<style lang="scss">
.change-password-container {
  .change-password-wrapper {
    max-width: 1240px;
    margin: auto;

    .change-password-row {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      background: var(--v-background-darken1);

      .change-password-input {
        padding-right: 12px;
        .v-input__slot {
          color: transparent;
          &::before,
          &::after {
            content: none;
          }
        }
      }
    }
    .profile-divider {
      width: 100%;
      height: 1px;
      background: var(--v-background-base);
    }
    .change-password-button {
      color: #ffffff;
      width: 80%;
      margin: 12px auto;
      padding: 12px;
      background: var(--v-primary-base);
      text-align: center;
      border-radius: 5px;
    }
  }
}
</style>
